html,
body {
    height: 100%;
}

#root,
div.App,
div.content,
.full-height {
    height: 100%;
}

table {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: start;
    width: 700px;
}

.full-height {
    display: flex;
    justify-content: center;
    align-items: center;
}

#reason_other_details {
    border-radius: 6px;
    resize: none;
    padding: 10px;
}

#submit_optout {
    padding: 10px;
    margin: 10px;
}

.tablehead {
    text-align: -webkit-center;
    font-size: xxx-large;
    padding-bottom: 50px;
}