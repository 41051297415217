@media (max-width:767px) {
    .btm-creds{
        position: relative;
        bottom: 0;
        right: 0;
        display: block;
        text-align: right;
        margin-top: 30px;
        width: 100%;
        top: 30px;
    }
}
@media (min-width: 768px) and (max-width: 1000px) {
    .btm-creds{
        position: relative;
        bottom: 0;
        right: 0;
        display: block;
        text-align: right;
        margin-top: 30px;
        width: 100%;
        top: 30px;
    }
}

@media (min-width: 1001px) and (max-width: 1170px) {
    .btm-creds{
        display: block;
        text-align: right;
        margin-top: 30px;
        width: 100%;
    }
}
