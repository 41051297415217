/* The device with borders */
.smartphone {
  position: absolute;
  width: 320px;
  height: 600px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  top: 0;
  right: -340px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 289px;
  height: 488px;
  background: white;
  overflow-y: scroll;
}

.content {

  &::-webkit-scrollbar {
   width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0D2645;
  

   &:hover {
    background: #00AAF6;
   }
  }
}

.inner {
  padding: 10px;
}

.sorted .msg {
    color: #FFFFFF;
    background-color: #45A9F0;
    border-radius: 15px;
    padding: 5px 19px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    width: 100%;
    margin-bottom: 10px;
}

.msgcontainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.sorted {
  flex: 70;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}
.time {
  align-items: flex-end;
  flex: 30;
  flex-direction: row;
  justify-content: end;
}

.sorted p {
  margin: 0;
}

.time p {
  font-size: 12px;
}