.c_layout-feature {
    margin-top: 20px
}

@media (min-height: 600px) and (max-height: 720px) {
    .c_layout-feature {
        margin-top: 10px
    }
}

.c_layout-feature__row {
    display: flex;
    margin-bottom: 20px
}

.c_layout-feature__item {
    max-width: 250px;
    border: 1px solid #C0BCBC;
    border-radius: 10px;
    margin-right: 20px;
    padding: 15px
}

@media (min-height: 600px) and (max-height: 720px) {
    .c_layout-feature img {
        width: 33px
    }
}

.c_layout-feature h5 {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 600;
}

.c_layout-feature p {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px
}

.c_layout-feature .arrow-link {
    float: right
}

@media (max-width:767px) {
    .c_layout-feature__row {
        display: block;
        margin:0;
    }
    .c_layout-feature__item{
        max-width: 100%;
        margin-right:0;
        margin-bottom:20px;
    }
    .c_layout-feature .arrow-link {
        float: none;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

@media (min-width:1920px) {
    .c_layout-feature h5 {
        font-size: 1.1rem;
    }
}