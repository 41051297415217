.cs-conversation.cs-conversation--active {
    color: #2a6b9c;
    background-color: #FEF5D9;
}

.cs-message--outgoing .cs-message__content {
    color: #FFFFFF;
    background-color: #122643;
    border-radius: 5px;
    padding: 17px 19px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
}

.cs-message.cs-message--outgoing.cs-message--single .cs-message__sent-time {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #BABABA;
}

.cs-message--incoming .cs-message__content {
    color: #FFFFFF;
    background-color: #45A9F0;
    border-radius: 5px;
    padding: 17px 19px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
}

.cs-message.cs-message--incoming.cs-message--single .cs-message__sent-time {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #BABABA;
}

.cs-search {
    border: 0.5px solid #BABABA;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    align-items: center;
    position: relative;
    padding: 0.6em 0.9em;
    font-size: inherit;
    font-family: inherit;
    background-color: #FFFFFF;
}

.cs-search__input {
    background-color: #FFFFFF;
}

.cs-button--send {
    color: #122643;
}

.cs-message-input__content-editor-wrapper {
    box-sizing: border-box;
    position: relative;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0.6em 0.9em;
    flex-grow: 1;
    border: 0.5px solid #BABABA;
    border-radius: 6px;
}

.cs-message-input__content-editor-container,
.cs-message-input__content-editor,
.cs-conversation-header,
.cs-conversation-header__content,
.cs-conversation-header__user-name,
.cs-conversation-header__info,
.cs-expansion-panel__header,
.cs-message-input--disabled,
.cs-message-input__content-editor-wrapper {
    background-color: #FFFFFF !important;
}

.cs-search__search-icon {
    color: #122643;
}

.cs-conversation {
    border: 1px solid;
    border-color: #E7E7ED;
}

.convo-header-4765 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
}

.convo-selections-4765 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.convo-selections-4765--selected {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #45A9F0;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.react-daterange-picker__calendar {
    z-index: 999999;
}

.react-daterange-picker__wrapper {
    border: 0.5px solid #BABABA;
    box-sizing: border-box;
    border-radius: 1px;
    padding: 5px;
}

.header-container-9547 {
    justify-content: flex-end;
    margin-bottom: 30px;
}

@media (max-width: 600px) {
    .header-container-9547 {
        justify-content: flex-start;
        margin-bottom: 0px;
    }
}

#sidebar-4874 {
    min-width: 250px;
}

@media (max-width: 768px) {
    .cs-main-container--responsive .cs-search {
        display: flex;
    }
}

.cs-button--send {
    align-self: center !important;
}

.MuiIconButton-label {
    color: #00AAF6 !important;
}

.cs-conversation-header {
    -webkit-user-select: auto !important;
    user-select: auto !important;
}

.react-daterange-picker__calendar {
    z-index: 4!important;
}

a:hover {
    color: #0056b3;
    text-decoration: none;
}