.c-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    margin-bottom: 20px
}

@media (max-width: 767px) {
    .c-header {
        flex-direction: column;
        align-items: flex-start
    }
}

.c-header h3 {
    font-weight: 700
}

.c-button {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 20px 9px 20px;
    line-height: inherit;
    text-transform: inherit;
    text-align: center;
    border: 0px solid #0D2645;
    border-radius: 5px;
    color: #fff;
    background-color: #0D2645;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 0;
    outline: none;
    text-decoration: none;
    position: relative;
    transition: all 0.2s
}

.c-button.button-radius {
    border-radius: 50px
}

.c-button:hover {
    background-color:#45A9F0;
    -moz-transition: 0.25s ease-in-out all;
    -o-transition: 0.25s ease-in-out all;
    -webkit-transition: 0.25s ease-in-out all;
    transition: 0.25s ease-in-out all
}

.c-button:focus {
    background-color: #000;
    border-color: #000
}

.c-button--rounded {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    -moz-transition: 0.25s ease-in-out all;
    -o-transition: 0.25s ease-in-out all;
    -webkit-transition: 0.25s ease-in-out all;
    transition: 0.25s ease-in-out all
}

.c-button--large {
    font-size: 22px;
    padding: 14px 58px 17px 58px
}

.c-button--secondary {
    color: #0D2645;
    background-color: #00AAF6
}

.c-button--secondary:active {
    color: #0D2645 !important;
    background-color: #d5deef !important;
    border-color: #d5deef !important
}

.c-button--secondary:hover {
    color: #0D2645;
    background-color: #45A9F0;
    -moz-transition: 0.25s ease-in-out all;
    -o-transition: 0.25s ease-in-out all;
    -webkit-transition: 0.25s ease-in-out all;
    transition: 0.25s ease-in-out all
}

.c-button--secondary:focus {
    color: #0D2645;
    background-color: #dabb56;
    border-color: #dabb56
}

.c-button--link {
    background-color: transparent;
    border-radius: 0;
    border-bottom: 2px solid;
    padding: 0
}

.c-button--link:hover {
    background-color: inherit;
    color: #005cbf;
    border-color: #005cbf
}

.c-button--link:focus {
    background-color: inherit
}

.c-button--link:active {
    color: #005cbf !important;
    background-color: inherit !important
}

.c-button::-moz-focus-inner {
    border: 0
}

.c-button:disabled {
    cursor: not-allowed
}

.c-button img {
    width: 18px;
    margin-right: 8px
}

.c-button.btn-icon {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center
}

.item-flex .c-button {
    margin: 0
}

.btn-primary.disabled.c-button--secondary,
.btn-primary:disabled.c-button--secondary {
    color: #0D2645
}

a.c-button {
    color: #fff
}

a.c-button:hover {
    color: #fff
}

.c-header__item .c-button {
    margin-left: 20px
}

@media (max-width: 767px) {
    .c-header__item .c-button {
        margin: 10px 10px 0 0;
        font-size: 14px;
        padding: 9px 10px 9px 10px
    }
}


.c_layout-login form .action-link .c-button {
    margin: 0
}