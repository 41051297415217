/*--------------------------------------------------------------
 General
--------------------------------------------------------------*/
.body, body {
  font-family: "Open Sans", sans-serif;
  color: #0D2645;
  font-size: 16px;
}

a {
  color: #0D2645 ;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

table a:hover {
  color: #00AAF6;
}

.nav-menu a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.login-link a {
  color: #fff;
  padding: 5px 0 2px 0;
}

.login-link a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.login-link a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

p {
  font-size: 18px;
  line-height: 34px;
}


/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #0D2645;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #37517e;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #47b2e4;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #209dd8;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#header.header-scrolled, #header.header-inner-pages {
  background: #0D2645;
  border: 0;
  box-shadow: 0 0 20px rgba(0,0,0,0.4);
}

#header .logo svg {
  height: auto;
  width: 140px;;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 15px 10px 15px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #fff;
  transition: 0.3s;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #ffffff;
}

.nav-menu .drop-down ul {
  border-radius: 8px;
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #0c3c53;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #47b2e4;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

/* Get Startet Button */
.login-link .get-started-btn {
  margin-left: 15px;
  color: #fff;
  border-radius: 5px;
  padding: 6px 25px 7px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #fff;
  font-weight: 600;
}

.login-link .get-started-btn::before {
  display: none;
}

.login-link .get-started-btn:hover {
  background: #0D2645;
  color: #fff;
  border: 1px solid #0D2645;
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 99999;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
}

.mobile-nav {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #0D2645;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
  font-size: 22px;
  text-align: center;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #0D2645;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(40, 58, 90, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #0D2645;
  font-size: 32px;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
  background-image: url('../img/hero-bg-rectangle.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero .container {
  padding-top: 50px;
}

.hero h1 {
  margin: 0 0 10px 0;
  font-size: 44px;
  font-weight: 600;
  line-height: 56px;
  color: #fff;
}

.pt-lg-6 {
  padding-top: 5rem!important;
}

.hero p {
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 35px;
}

.hero .btn-get-started {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #0D2645;
  background: #fff;
}

.hero .btn-get-started:hover {
  background: #0D2645;
  color: #fff;
}

.hero .btn-watch-video {
  font-weight: 600;
  display: inline-block;
  padding: 7px 0 3px 0;
  transition: 0.5s;
  margin: 10px 0 0 20px;
  color: #fff;
  position: relative;
  border-bottom: 2px solid #fff;
}

.hero .btn-watch-video i {
  color: #fff;
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 7px;
  transition: 0.3s;
}

.hero .btn-watch-video:hover i {
  color: #47b2e4;
}

.hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 100px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.section-title h2 {
  font-size: 42px;
  font-weight: 100;
  color: #0D2645;
  line-height: 54px;
}

.section-title p {
  font-size: 20px;
  font-weight: 100;
  margin-top: 30px;
  margin-bottom: 40px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  box-shadow: 8px 10px 34px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 5px;
}

.services .icon-box .icon {
  margin-bottom: 10px;
}

.services .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  margin-top: 25px;
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: -0.5px;
}


.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  transform: translateY(-10px);
}


.services .icon-box ul {
  text-align: left;
  padding-left: 18px;
}

.services .icon-box ul li {
  list-style: none;
  margin-bottom: 15px;
  line-height: 28px;
  position: relative;
  color: #4A4A4A;
}

.services .icon-box ul li:before {
  content: '';
  width: 6px;
  height: 6px;
  display: block;
  background-color: #C4C4C4;
  position: absolute;
  left: -18px;
  top: 12px;
  border-radius: 80px;
}

section.services .CTA-btn.btn-blue {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.btn {
  background-color: #0D2645;
  color: #fff;
  padding: 14px 20px;
  border-radius: 5px;
  border: 2px solid #0D2645;
  font-weight: 700;
}

.btn-large {
  padding: 20px 30px;
  transition: all .25s;
}



.btn:hover {
  color: #0D2645;
  background-color: #fff;
  border: 2px solid #0D2645;
}


.mail-cta {
  background: -webkit-gradient(linear,left top,right top,from(#102a4a),color-stop(0,#2e5e85),to(#4c93c1));
  background: linear-gradient(90deg,#102a4a,#143051 0,#4c93c1);
  color: #fff;
}

.mail-cta-content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.mail-cta-content form {
  margin-top: 45px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 20px;
}

.mail-cta-content form  input[type="email"] {
  border: 0;
  padding: 15px 18px;
  width: calc(100% - 130px);
  text-transform: inherit;
}

.mail-cta-content form input[type="submit"] {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  padding: 0 30px;
  border-radius: 5px;
}

h3 {
  font-size: 26px;
  font-weight: 100;
  line-height: 44px;
}

h2.big-heading {
  font-size: 56px;
}

section.get-in-touch .section-title h3 {
  margin-top: 30px;
  margin-bottom: 40px;
}

form.contact-form {
  max-width: 800px;
  margin: 0 auto;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1240px;
}

span.heading-sms {
  font-size: 55px;
  font-weight: 800;
  color: #FFC555;
}

.mail-cta ::placeholder {
  font-weight: 600;
}

.mail-cta input {
  text-transform: uppercase;
}

::-webkit-input-placeholder { /* Edge */
  font-size: 18px;
  color: #67696C;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-size: 18px;
  color: #67696C;
}

::placeholder {
  font-size: 18px;
  color: #67696C;
}

input.form-control,
textarea.form-control {
  font-size: 18px;
  color: #67696C;
  padding: 28px 20px;
  border: 1px solid #e4e2ef;
}

form.contact-form .form-control {
  background-color: #F1F5FA;
}

form.contact-form .form-control::placeholder {
  color: #93A8C5;
}

textarea.form-control {
  padding: 20px;
}

form.contact-form .form-group {
  padding: 0 18px;
  margin-bottom: 36px;
}

form.contact-form button.btn {
  padding: 14px 40px;
}

.sms-tips {
  width: 100%;
  height: 650px;
  background-image: url('../img/sms-tips-background.svg');
  background-position: 100% 20px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 0;
  margin-top: 30px;
}

.sms-tips .section-title {
  max-width: 900px;
}

.sms-tips .section-title h2.big-heading {
  margin-bottom: 60px;
}

.sms-tips .section-title .btn {
  padding: 15px 40px;
}


section.coming-soon {
  background-color: #00AAF6;
  color: #fff;
  padding: 30px 0;
}

section.coming-soon .coming-soon-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section.coming-soon .coming-soon-content .coming-soon-feature {
  display: flex;
}

section.coming-soon .coming-soon-content h3 {
  margin: 0;
  font-size: 34px;
  text-transform: uppercase;
}

section.coming-soon .coming-soon-content .coming-soon-feature .feature-list {
  display: flex;
  align-items: center;
}

section.coming-soon .coming-soon-content .coming-soon-feature .feature-list p {
  margin: 0;
}

section.coming-soon .coming-soon-content .coming-soon-feature .feature-list img {
  margin-right: 13px;
}

section.coming-soon .coming-soon-content .coming-soon-feature .feature-list:last-child {
  margin-left: 50px;
}

#footer {
  font-size: 14px;
  background: #F5F5F5;
}

#footer .footer-top {
  padding: 70px 0 70px 0;
  background: #0D2645;
  color: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 0;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  font-weight: 700;
  color: #ffffff;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  margin-bottom: 40px;
  color: #fff;
  max-width: 330px;
  line-height: 26px;
}

ul.contact-info li p {
  margin-bottom: 0 !important;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #37517e;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul a {
  color: #ffffff;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

#footer .copyright {
  color: #535353;
}

ul.contact-info {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

ul.contact-info li {margin-right: 70px;max-width: 240px;display: flex;align-items: flex-start;}

ul.contact-info li i {
  font-size: 18px;
  padding-top: 4px;
}

ul.contact-info li a, ul.contact-info li p {
  color: #fff;
  margin-left: 12px;
}

#footer .copyright a {
  color: #535353;
}

.social-links a {
  color: #535353;
  margin-left: 16px;
  font-size: 18px;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.mobile-nav-active button.mobile-nav-toggle {
  top: 20px;
}

.mobile-menu-link {
  display: none;
}

.hero .CTA {
  margin-bottom: 50px;
}

.arrow-link {
  display: flex;
  align-items: center;
}

.arrow-link {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.arrow-link:hover {
  color: #fff;
}

.arrow-link img {
  margin-left: 10px;
  transition: all 0.25s;
}

.arrow-link:hover img {
  margin-left: 15px;
  transition: all 0.25s;
}

.pt-lg-32 {
  padding-top: 40px !important;
}

section.services .CTA-btn.btn-blue a.btn.btn-large img {
  margin-left: 10px;
  margin-top: -2px;
  border-radius: 50px;
  padding: 2px;
  transition: all .25s;
}

section.services .CTA-btn.btn-blue a.btn.btn-large:hover img {
  background: #0D2645;
  padding: 2px;
  border-radius: 50px;
  transition: all .25s;
}


@media only screen and (max-width: 767px) {
  .login-link {
    display: none;
  }
  section.services .CTA-btn.btn-blue a.btn.btn-large img {
    display: none;
  }
  .mobile-menu-link {
    display: block;
  }
  .hero img.animated {
    max-width: 180px;
  }
  .hero .hero-img.text-right {
    text-align: center !important;
  }
  .hero .container {
    padding-top: 20px;
  }

  .hero .CTA {
    margin-bottom: 40px;
  }

  .arrow-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero h1 {
    margin: 0 0 10px 0;
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    text-align: center;
  }
  span.heading-sms {
    font-size: 45px;
    font-weight: 800;
    color: #FFC555;
  }
  .hero .animated {
    animation: none;
  }
  .hero p {
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 35px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }
  .hero .CTA {
    text-align: center;
  }
  section {
    padding: 50px 0;
    overflow: hidden;
  }
  .hero {
    width: 100%;
    height: auto;
    background-image: none;
    padding-top: 90px;
    background-color: #2752ac;
  }
  .hero .btn-watch-video {
    margin: 20px 0 0 0px;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: 100;
    color: #0D2645;
    line-height: 42px;
  }
  .section-title p {
    font-size: 18px;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .services .icon-box {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .services .align-items-stretch:last-child .icon-box {
    margin-bottom: 0;
  }
  section.services .CTA-btn.btn-blue {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 22px;
    font-weight: 100;
    line-height: 38px;
  }
  .mail-cta-content form input[type="email"] {
    border: 0;
    padding: 15px 18px;
    width: calc(100% - 0px);
    text-transform: inherit;
    border-radius: 5px;
  }
  .pt-lg-32 {
    padding-top: 30px !important;
  }
  .mail-cta-content form {
    margin-top: 35px;
    background: transparent;
    padding: 6px 10px;
    position: relative;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
  }
  .mail-cta-content form input[type="submit"] {
    position: relative;
    top: 30px;
    right: 0;
    bottom: 0;
    padding: 10px 30px;
    border-radius: 5px;
    left: 0;
    margin: 0 auto;
  }
  section.get-in-touch h2.big-heading {
    font-size: 36px;
    font-weight: 400;
  }
  section.get-in-touch .section-title h3 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  form.contact-form .form-group {
    padding: 0 0;
    margin-bottom: 26px;
  }
  form.contact-form button.btn {
    padding: 14px 40px;
    margin-top: 30px;
  }
  .sms-tips .section-title h2.big-heading {
    margin-bottom: 40px;
  }
  .sms-tips .section-title .btn {
    padding: 10px 23px;
  }
  .sms-tips {
    width: 100%;
    height: 320px;
    background-image: url(../img/sms-tips-background.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    margin-top: 30px;
  }
  section.coming-soon .coming-soon-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  section.coming-soon .coming-soon-content .coming-soon-feature {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
  }
  section.coming-soon .coming-soon-content .coming-soon-feature .feature-list:last-child {
    margin-left: 0;
    margin-top: 15px;
  }
  #footer .footer-top {
    padding: 40px 0 30px 0;
    background: #0D2645;
    color: #fff;
  }
  ul.contact-info {
    flex-direction: column;
  }
  ul.contact-info li {
    margin-right: 0px;
    max-width: 240px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 40px;
  }
  #footer .footer-top .footer-contact p {
    max-width: 100%;
  }
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #footer .copyright {
    color: #535353;
    text-align: center;
  }
  .social-links {
    margin-top: 20px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-link {
    display: none;
  }
  .mobile-menu-link {
    display: block;
  }
  .hero img.animated {
    max-width: 180px;
  }
  .arrow-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero .hero-img.text-right {
    text-align: center !important;
  }
  .hero .container {
    padding-top: 20px;
  }
  .hero h1 {
    margin: 0 0 10px 0;
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    text-align: center;
  }
  span.heading-sms {
    font-size: 45px;
    font-weight: 800;
    color: #FFC555;
  }
  .hero .animated {
    animation: none;
  }
  .hero p {
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 35px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }
  .hero .CTA {
    text-align: center;
  }
  section {
    padding: 50px 0;
    overflow: hidden;
  }
  .hero {
    width: 100%;
    height: auto;
    background-image: none;
    padding-top: 90px;
    background-color: #2752ac;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: 100;
    color: #0D2645;
    line-height: 42px;
  }
  .section-title p {
    font-size: 18px;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .services .icon-box {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .services .align-items-stretch:last-child .icon-box {
    margin-bottom: 0;
    width: 100%;
  }
  section.services .CTA-btn.btn-blue {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 22px;
    font-weight: 100;
    line-height: 38px;
  }
  section.get-in-touch h2.big-heading {
    font-size: 36px;
    font-weight: 400;
  }
  section.get-in-touch .section-title h3 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  form.contact-form button.btn {
    padding: 14px 40px;
    margin-top: 30px;
  }
  .sms-tips .section-title h2.big-heading {
    margin-bottom: 40px;
  }
  .sms-tips .section-title .btn {
    padding: 10px 23px;
  }
  .sms-tips {
    width: 100%;
    height: 320px;
    background-image: url(../img/sms-tips-background.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    margin-top: 30px;
  }
  #footer .footer-top {
    padding: 40px 0 30px 0;
    background: #0D2645;
    color: #fff;
  }
  ul.contact-info {
    flex-direction: column;
  }
  ul.contact-info li {
    margin-right: 0px;
    max-width: 240px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 40px;
  }
  #footer .footer-top .footer-contact p {
    max-width: 100%;
  }
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #footer .copyright {
    color: #535353;
    text-align: center;
  }
  .social-links {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1160px) {
  .container {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}
