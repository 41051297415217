.modal-main {

    width: 500px;
}

#form-dialog-title {
    border: 0;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 20px;
}

.modal-title {
    font-family: "Open Sans", sans-serif;
    font-size: 19px !important;
    text-transform: capitalize;
    margin-bottom: 0 !important;
    line-height: 1.5 !important;
    float: left;
}

#form-dialog_body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 20px;
}

#form-dialog-title .close {
    margin-left: 13rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

label {
    font-size: 14px;
    color: #464646;
    margin-bottom: 5px;
}

.form-groups {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
}

.form-controls {

    border: 1px solid rgba(119, 134, 158, 0.6);
    color: #000000;
    padding: 9px 15px 10px 15px;
    height: 48px;
    font-size: 14px;
    border-radius: 6px;
    display: block;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
}

textarea.form-controls {
    height: auto;
}

textarea {
    resize: none !important;
}

.text-limit {
    color: #464646;
    font-size: 14px;
    margin-top: -15px;
}

#dialog-footer {
    padding: 10px 10px 30px 10px;
}

.c-button {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 20px 9px 20px;
    line-height: inherit;
    text-transform: inherit;
    text-align: center;
    border: 0px solid #0D2645;
    border-radius: 5px;

    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 0;
    outline: none;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
}

.c-button_primary {
    color: #fff;
    background-color: #0D2645;
}

.c-button_secondary {
    color: #0D2645;
    background-color: #00AAF6 !important;
}

.plus-icn {
    margin-right: 15px;
    width: 36px;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

a.close.disabled {
    pointer-events: none
}

#form-dialog-title h2 {
    width: 100%
}