@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;

}

html {
    min-height: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    position: relative;
    margin: 0;
    padding: 0;
    padding-bottom: 0.1px;
    overflow-y: visible;

}

/* Main Head Bar css */
#main-head {
    color: #0D2645;
    font-weight: 800;
    margin-left: 5px;
    padding-top: 5px;
}

#wrap-btn {
    /* border: 2px solid teal; */
    /* position: absolute;
    right: 85px; */
    padding-top: 10px;
}

.card-head #wrap-btn {
    /* border: 2px solid teal; */
    /* position: absolute;
    right: 85px; */
    padding-top: 0px;
    margin-left: 10px;
}

#plan-btn,
#credit-btn {
    background: #0D2645;
    border: 0px solid #0D2645;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 20px;
    /* margin-top: 15px; */
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 20px 9px 20px;
    outline: none;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
}

#credit-btn {
    background: #00AAF6;
    border: 1px solid #00AAF6;
    color: #0D2645;
}

/* Main Head Bar css End */


/* Welcome Tab Css */
.welcome-tab_wrap {
    display: flex;
    background: #F8F8F8;
    box-shadow: 0px 4px 5px 4px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 10px 60px;
    margin-bottom: 50px;
}

.welcome_left {
    /* border: 2px solid teal; */
    width: 40%;
    height: 100%;
    float: left;
    padding: 30px;
}

.welcome_right {
    /* border: 2px solid yellow; */
    padding: 10px 10px;
    height: 100%;
    width: 60%;
    float: left;
}

.welcome_right .head-gredient {
    /* color: #0D2645; */
    display: inline-block;
    background-color: #0D2645;
    background-image: linear-gradient(80deg, #0D2645, #00AAF6);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    /* font-size: 26px; */
    letter-spacing: 0.6px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.welcome_right .spn-txt {
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-left: 8px;
    display: inline-block;
    color: #00AAF6;
    margin-bottom: 0px;
}

.welcome_right h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    color: #0D2645;
    font-size: 20px;
}

.welcome_right .info_para {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-bottom: 10px;
}

.welcome_right .info_para span {
    font-weight: 900;
}

.cntct_info .cntct_email {
    display: inline;
    font-weight: 700;

}

.cntct_info .cntct_ph {
    margin-left: 20px;
    font-weight: 700;
    display: inline;
}

.cntct_email span {
    font-weight: 700;
    color: #00AAF6;
    text-transform: uppercase;
}

.cntct_ph span {
    font-weight: 700;
    color: #00AAF6;
    text-transform: uppercase;
}

/* Welcome Tab Css End*/

/*Second Section Css*/
.sms-auto_tab {
    border: 3px solid #00AAF6;
    border-radius: 15px;
    padding: 15px;
}

.sms-auto_tab h2 {
    color: #0D2645;
    font-size: 26px;
    font-weight: 800;
    text-transform: uppercase;
}

.sms-auto_tab p {
    display: block;
    font-size: 16px;
    font-weight: 400;
    height: 120px;
    line-height: 1.5;
    overflow: auto;
    text-transform: capitalize;
}

.sms-auto_tab img {
    float: right;
}

.clear {
    clear: both;
}

/*Second Section Css End*/

/*Footer Section css*/
.footer-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 25px;
    /* background: #F8F8F8; */
}

.footer-icon {
    margin-bottom: 5px;
}

.footer-icon img {
    margin-left: 8px;
    cursor: pointer;
}


/*Footer Section css End*/
.c-sidebar__nav-link {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    transition: all .3s
}

.c-sidebar__nav-link img {
    width: 24px;
    transition: all .3s
}

.c-sidebar__nav-link span {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    margin-left: 10px;
    transition: all .3s;
    font-size: 14px;
    font-weight: 600
}

.c-sidebar__nav-link--active {
    background-color: #00AAF6;
    transition: all .5s
}

.c-sidebar__nav-link--active img {
    filter: grayscale(100%) brightness(8%) sepia(70%) hue-rotate(-190deg) saturate(340%) contrast(0.9);
    transition: all .3s
}

.c-sidebar__nav-link--active span {
    color: #0D2645;
    transition: all .5s
}

.c-sidebar__nav-link:hover {
    background-color: #00AAF6 !important;
    transition: all .5s
}

.c-sidebar__nav-link:hover img {
    filter: grayscale(100%) brightness(8%) sepia(70%) hue-rotate(-190deg) saturate(340%) contrast(0.9);
    transition: all .3s
}

.c-sidebar__nav-link:hover svg {
    filter: grayscale(100%) brightness(8%) sepia(70%) hue-rotate(-190deg) saturate(340%) contrast(0.9);
    transition: all .3s
}

.c-sidebar__nav-link:hover span {
    color: #0D2645;
    transition: all .5s
}

.c-sidebar__nav-link--active {
    background-color: #00AAF6 !important;
    transition: all .5s;
}

.c-sidebar__nav-link--active svg {
    filter: grayscale(100%) brightness(8%) sepia(70%) hue-rotate(-190deg) saturate(340%) contrast(0.9);
    transition: all .3s
}

.c-sidebar__brand {
    text-align: center;
    /* padding: 30px */
}


.c-header__toggle-btn {
    background: transparent;
    border: 0;
    position: fixed;
    /* top: 60px; */
    /* left: 222px; */
    cursor: pointer
}

.c-header__toggle-btn img {
    transition: all .3s
}

.c-header__toggle-btn--show img {
    transform: rotate(45deg);
    transition: all .3s
}

.makeStyles-root-1, .min-height-800 {
    min-height: 800px;
}

.tooltip {
    position: relative;
    display: inline-block!important;
    opacity: 1!important;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 600px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  label {
    color: #464646!important;
  }