.c_layout-welcome {
    display: flex;
    background: #F8F8F8;
    box-shadow: 0px 4px 5px 4px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 40px 60px;
    margin-bottom: 50px
}

@media (max-width: 767px) {
    .c_layout-welcome {
        flex-direction: column;
        border-radius: 10px;
        padding: 20px 20px
    }
}

.c_layout-welcome__item h3 {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.6px
}

.c_layout-welcome__item span {
    font-size: 22px;
    color: #0D2645;
    margin: 20px 0 20px 0;
    display: block
}

.c_layout-welcome__list {
    margin-left: 50px
}

@media (max-width: 767px) {
    .c_layout-welcome__list {
        margin-left: 0px
    }
}

.c_layout-welcome__info {
    display: flex
}

@media (max-width: 767px) {
    .c_layout-welcome__info {
        flex-direction: column
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .c_layout-welcome__info {
        flex-direction: column
    }
}

.c_layout-welcome__info-item {
    display: flex;
    margin-right: 30px
}

.c_layout-welcome__info-item span {
    color: #00AAF6;
    font-weight: 700
}

.c_layout-welcome__info-item p {
    margin: 0 0 0 10px;
    font-weight: 700
}

.c_layout-welcome__img {
    min-width: 300px
}

@media (max-width: 767px) {
    .c_layout-welcome__img {
        min-width: 100px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .c_layout-welcome__img {
        min-width: 200px
    }
}