@media (max-width:767px) {
    .white-logo {
        position: relative;
        top: 0;
        right: 0;
        cursor: pointer;
        margin: 20px 0;
    }
}
@media (min-width: 768px) and (max-width: 1000px) {
    .white-logo {
        position: relative;
        top: 0;
        right: 0;
        cursor: pointer;
        margin: 20px 0;
    }
}