.App {
  text-align: left;
  background-color: white;
}

sup {
  margin-left: 4px;
  margin-right: 4px;
}

.headerTitleSpan {
  background-color: #0D2645;
  width: max-content;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grow:hover {
  transform: scale(1.1);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-color: white;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.zoom:hover {
  transform: scale(1.05);
  transition: all .3s;
}

.zoombutton:hover {
  transform: scale(1.5);
}

/* LogIn */
.login-wrap .login-wrap_heading {
  font-weight: 700;
}

/* Dashboard */
.MuiDialogContent-root {
  overflow-x: hidden;
}

.grid-bottom-spacing {
  margin-bottom: 70px !important;
}
.feedback-section {
  float: right;
  margin-bottom: 80px;
  margin-left: 10px;
}
.footer-logo {
  float: right;
  margin-top: 50px;
  margin-right: -95px;
}

 /* SMS Templates */
.middle-container {
  padding-bottom: 50px !important;
}
.template-info__item {
  width: 400px;
}
.template-info__item .section-message {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
}

.search-box {
  float: none;
}
.col-search #wrap-btn {
  margin-left: 0px;
} 
.col-search #plan-btn {
  margin-right: 0px;
} 
.card-head.col-search .input-box {
  padding: 9px 5px 10px 5px;
}

.modal-main {
  overflow-x: hidden;
}

/* SMS Campaign */
.sms-details {
  position: relative;
}
.sms-details .c-button {
  color: #ffffff;
}

/* Modal- Import Template */
.import-template-modal {
  width: 500px;
  height: 600px;
  overflow-x: hidden;
}
.template-info {
  width: 100%;
}
.template-info__item--border {
  width: 100%;
}

/* Settings */
.panal-width {
  width: 90%;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-10 {
  margin-bottom: 10px;
  display: block;
}

/* Conversation */
.conversation-section {
  padding-bottom: 70px;
}
.conversation-section .cs-main-container .cs-conversation-header,
.conversation-section .cs-main-container .cs-conversation-header__user-name,
.conversation-section .cs-main-container .cs-conversation-header__user-name {
  background-color: #F6F6F6 !important;
}
.cs-message-input__content-editor-container, 
.cs-message-input__content-editor, 
.cs-conversation-header, .cs-conversation-header__content, 
.cs-conversation-header__user-name, .cs-conversation-header__info, 
.cs-expansion-panel__header, .cs-message-input--disabled, 
.cs-message-input__content-editor-wrapper {
  background-color: #F6F6F6 !important;
}
.conversation-section .cs-search,
.conversation-section .cs-search__input {
  background-color: #F6F6F6;
}

.conversation-section .option p {
  margin-bottom: 0;
}
.conversation-section .option p a {
  font-size: 16px;
}
.conversation-section .option p a:hover {
  color: rgb(0, 170, 246);
}

.icon-btn .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  float: right;
}
button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.conversation-section  .col-left {
  float: left;
  width: 70%;
}
.conversation-section  .col-left .user-name {
  padding: 0; 
  margin: 0; 
  font-Size: 14px; 
  font-Weight: 700; 
  line-Height: 1;
}
.conversation-section  .col-left .reference-no {
  padding: 0; 
  margin: 0; 
  font-Size: 12px; 
  font-Weight: 600;
}
.conversation-section  .col-right {
  float: left;
  width: 30%;
}

.icon-btn {
  display: flex;
  justify-content: right;
}


/* Footer */
.section-footer {
  z-index: 10;
}

/* Responsive Code */
/* Mobile View- LogIn/SignUp */
@media (max-width: 767px) {
  .brand-name {
    text-align: right;
  }
  .login-wrap {
    margin-top: 40px;
  }
  .white-logo {
      position: static;
  }
  .loginMain {
    padding: 30px 25px 0;
  }
  .left-content {
    display: none;
  }
  .btm-creds {
    position: static !important;
  }

  /* Dashboard */
  .sms-auto_tab h2 {
    font-size: 20px;
  }

  /* SMS Templates */
  .c-header h3 {
    margin-bottom: 15px !important;
  }
  .c_layout-welcome {
    padding: 15px 25px;
  }
  .c_layout-welcome .ml-20 {
    margin-left: 20px;
  }

  .middle-container {
    padding-bottom: 70px !important;
    padding: 10px;
  }
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: transparent !important;
  }

  .col-search #wrap-btn {
    margin-left: 0px;
  } 

  .modal-main {
    width: 300px;
  }
 
  /* Footer */
  .section-footer .footer-col {
    width: 100% !important;
  }
  .pl-70 {
    padding-left: 70px;
  }
  .text-lt {
    text-align: left !important;
  }

  /* SMS Campaign */
  .sms-details {
    position: static;
  }
  
  .mb-100 {
    margin-bottom: 100px !important;
  }

  .import-template-modal {
    width: 300px;
    height: 500px;
    overflow-x: hidden;
  }
  .import-template-modal .template-info__item--border img {
    width: 100%;
  }

  .hidden {
    display: none !important;
  }

  /* Settings */
  .panal-viewport .MuiExpansionPanelSummary-content {
    display: block;
  }
  .MuiFormControl-root {
    margin: 0 !important;
    min-width: auto !important;
  }

}

/* SMSTable Responsive */
@media (min-width: 0px) {
  .mui-box {
    width: 100% !important;
    display: grid !important;
  }
}

@media (min-width: 320px) and (max-width: 368px) {
  .card-head.col-search .input-box {
    width: 79%;
  }
  
}

@media (min-width: 0px) and (max-width: 410px) {
  .makeStyles-content-10 {
    padding: 0px !important;
    margin: 10px 5px 10px 5px !important;
  }

  .c-header {
    align-items: center;
  }
  .c-header h3 {
    text-align: center;
  }

  .middle-container {
    padding-bottom: 130px !important;
  }

  /* Dashboard- feedback section */
  .grid-bottom-spacing {
    margin-bottom: 50px !important;
  }
  .feedback-section {
    margin-bottom: 150px;
  }
  .account-details {
    margin-bottom: 120px !important;
  }

  /* SMS Campaign */
  .box-viewport-set {
    width: calc(100vw - 100px);
    margin: 0px 0px 10px 10px !important;
  } 
  .box-viewport-set.mb-100 {
    margin-bottom: 100px !important;
  } 
  
  .sms-details .c-button {
    font-size: 14px;
    padding: 9px 5px 9px 5px;
  }

  .contact-details .MuiStepper-root {
    padding: 10px 0;
  }
  .contact-details .MuiStep-horizontal {
    padding: 0px; 
  }

  .section-footer .footer-col a{
    font-size: 12px;
  }

  /* Settings */
  .panal-viewport {
    margin: 0px 0px 10px 15px !important;
  }

  .conversation-section {
    padding-bottom: 90px;
  }
 
}

@media (min-width: 0px) and (max-width: 499px) {
  .mb-button .c-button {
    display: block;
    width: 170px;
  }
  .mb-button .send-button {
    margin-left: 0 !important;
  }
  .width-auto .c-button{
    width: auto;
  }

  /* Conversation */
  .ml-10 {
    margin-left: 10px;
  }
  .fs-12 {
    font-size: 12px;
  }

}

@media (min-width: 410px) and (max-width: 445px) {
  /* SMS Campaign */
  .box-viewport-set {
    width: calc(100vw - 115px);
    margin: 0px 0px 10px 0 !important;
  }
  .box-viewport-set.mb-100 {
    margin-bottom: 100px !important;
  }
  .contact-details .MuiStepper-root {
    padding: 10px 0;
  }
  .contact-details .MuiStep-horizontal {
    padding: 0px; 
  }
}

@media (min-width: 0px) and (max-width: 1000px) {
  .card-head.col-search .input-box {
    padding: 9px 5px 10px 5px;
  }
  .col-search {
    padding: 15px 15px;
  } 
  .col-search .search-box {
    display: block;
  }
   .col-search .search-group {
    margin-bottom: 10px;
    clear: both;
  }
  .input-group-prepend {
    float: left;
  }
  .col-search .input-box {
    padding-right: 40px !important;
    width: 83%;
    float: left;
  }

  /* SMS Campaign */
  .sms-details .smartphone {
    position: static;
    margin: 0;
    width: auto;
  }
  .smartphone .content {
    width: auto;
  }
  .smartphone:after {
    background: transparent;
  }

  /* Settings */
  .panal-viewport {
    width: calc(100vw - 110px);
  }

}

/* Tablet View- LogIn/SignUp */
@media (min-width: 767px) and (max-width: 1000px) {
  .left-wrap {
    display: block !important;
    padding: 50px !important;
    height: auto !important;
  }

  .btm-creds {
    position: static !important;
  }

  .col-search .input-box {
    width: 92%;
  }

}

@media (min-width: 1001px) and (max-width: 1210px) {
  .card-head {
    padding: 15px 10px;
  }
  .card-head.col-search .input-box {
    width: 70%;
  }

   /* SMS Campaign */
   .sms-campaign-section .md-view {
    max-width: 570px !important;
    margin: 0px 0px 10px !important;
   }

}

@media (min-width: 1211px) and (max-width: 1415px) {
  /* SMS Campaign */
  .sms-campaign-section .md-view {
    max-width: 500px !important;
  }

}

/* Dashboard */
@media (min-width: 960px) and (max-width: 1240px) {
  .sms-auto_tab h2 {
    font-size: 18px;
  }
  
}
