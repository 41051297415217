@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input[type=text],
input[type=submit]:focus {
    outline: none;
    text-decoration: none;
    outline-color: transparent;
    outline-offset: 0rem;
}

html {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    line-height: 26px;
}

.ml-30 {
    margin-left: 30px;
}

.mb-20 {
    margin-bottom: 20px;
}

.wrapper {
    background-color: salmon;
}

.left-wrap {
    height: 100vh;
    /* padding: 60px 40px;  */
    padding: 0 50px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-image: url(sms-campaign-mobile.svg);
    background-repeat: no-repeat;
    background-size: auto;
    /* background-size: cover; */
    background-position: bottom right;
    height: 100vh;
}



.logo-container img {
    width: 250px;
}

.title-tab {
    /* margin-top: 30px; */
    margin: 15px 0 3px 0;


}

.title-tab h4 {
    color: #0D2645;
    font-size: 24px;
    margin-bottom: 3px;

}

.title-tab p {
    font-size: 21px;
    color: #0D2645;
    text-transform: capitalize;
    font-weight: 300;

}

.feature-tabs__wrap {
    /* border:2px solid teal; */
    width: 80%;
    margin-top: 30px;
    height: 450px;
    float: left;
}

.feature-tabs {
    border: 1px solid #C0BCBC;
    border-radius: 10px;
    padding: 15px;
    max-width: 230px;
    float: left;
    margin-right: 20px;
    /* box-shadow: inset 0 1px 3px 1px #DADADA; */
}

.feature-tabs h5 {
    margin-top: 10px;
    font-weight: 300;
    /* text-shadow: 2px 2px 5px  #DADADA; */
}

.feature-tabs p {
    text-transform: capitalize;
    /* text-shadow: 2px 2px 5px #DADADA; */
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 17px;
}

.feature-tabs .arw-img {
    margin-top: 2px;
    margin-right: 10px;
    float: right;
    text-shadow: 5px 5px 15px #DADADA;
    cursor: pointer;
}

.feature-tabs .mobile-img {
    margin-top: 5px;
}

.hero-img_sec {
    margin-top: 10px;
    /* border: 2px solid teal; */
    display: inline-block;
    padding: 50px 50px;
}

.hero-img-deleteme {
    height: 350px;
    width: 350px;
    opacity: 0.4;
}

/* Login Right side CSS  */

.loginMain {
    /* background-color:#0D2645; */
    /* background-image: linear-gradient(140deg,#0D2645 50%, rgba(251, 220, 119,.9)); */
    background-image: url(intro-login-shape-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 50px;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

/* .loginMain .brand-name h1 {
    color :#ffffff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 8px;
    text-align: right;
    margin-right: 35px;
} */

.white-logo {
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
}

.login-wrap {

    width: 100%;


}

.login-wrap .login-wrap_heading {

    font-size: 34px;
    margin-bottom: 30px;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 900;

}

.form-group {
    margin-bottom: 25px !important;
    position: relative;
    /* width: 280px; */

}

.login-textbx {

    border: 1px solid rgba(119, 134, 158, 0.6);
    color: #000000;
    padding: 9px 15px 10px 15px;
    height: 52px;
    width: 100%;
    font-size: 14px;
    border-radius: 6px;
    /* display: block; */
}

.action-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 40px; */
}

.forgot-link {

    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}

.frgt-pswd p {
    font-size: 16px;
}

.login-btn {
    padding: 15px;
}

.login-btn {
    padding: 9px 20px 9px 20px;
    border: 1px solid #00AAF6;
    border-radius: 5px;
    background-color: #00AAF6;
    color: #0D2645;
    font-weight: 600;
    outline: none;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
    line-height: inherit;
    text-transform: inherit;
    text-align: center;
}

.btm-creds {

    position: absolute;
    bottom: 20px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.btm-creds p {

    margin-bottom: 4px;
    font-size: 18px;
    color: #0D2645;
}

.btm-creds h6 {
    font-size: 16px;

    color: #DADADA;
    text-align: right;
    font-weight: 600;
    letter-spacing: 2px;
    color: #0D2645;
}

.btm-creds h6 span {
    color: #00AAF6;
}

.form .form-group {
    width: 100%;
}

.form .form-group input {
    width: 92%;
}


@media (max-width:767px) {
    .loginMain {
        display: block;
        padding: 30px 25px;
    }

    .form .form-group,
    .form,
    .form .form-group input {
        width: 100%;
        float: none;
    }

    .login-btn {
        margin-left: 0;
        float: none;
        margin-top: 10px;
    }

    .forgot-link {
        display: block;
        margin: 10px auto;
        top: 0;
    }

    .action-link {
        display: block;

    }
}

@media (min-width: 768px) and (max-width: 1000px) {

    .action-link {
        display: block;

    }
    
    .action-link .forgot-link {
        display: block;
        margin: 10px auto;
        top: 0;
    }

    input.login-btn.c-button {
        margin-top: 10px;
    }
}

@media (min-width: 1001px) and (max-width: 1170px) {
    .login-textbx {
        height: 45px;
    }

    .action-link {
        display: block;

    }

    .action-link .forgot-link {
        display: block;
        margin: 10px auto;
        top: 0;
    }

    input.login-btn.c-button {
        margin-top: 10px;
    }

    .form .form-group {
        margin-bottom: 20px !important;
    }
}

@media (min-width:1920px) {
    .loginMain {
        padding: 70px 8%;
    }
}