.clear {
   clear: both;
}

.text-blue {
   color: #45A9F0 !important;
}

#tb-rw {
   background-color: #F6F6F6;
}

.item-flex {
   display: flex;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
   align-items: center;
   float: right;
}

.card-head {
   padding: 15px 30px;
   background-color: transparent;
   border-bottom: 1px solid #BEBEBE;
}

.card-header--with-icon .plus-icn {
   margin-right: 15px;
   width: 36px;
}

.card-head input[type=text] {
   border: 1px solid rgba(119, 134, 158, 0.6);
   color: #000000;
   padding: 9px 15px 10px 15px;
   height: 52px;
   font-size: 14px;
   font-weight: 400;
   line-height: 1.5;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   background-color: #fff;
   background-clip: padding-box;
}

.input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%;
}

.input-group-prepend {
   display: flex;
   margin-right: -1px;
}

.input-group-text {
   display: flex;
   align-items: center;
   padding: 0.375rem 0.75rem;
   margin-bottom: 0;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   text-align: center;
   white-space: nowrap;
   border: 1px solid rgba(119, 134, 158, 0.6);
   background-color: transparent !important;
   padding: 8.5px 12px;
   border-right: 0;
   /* border-radius: 6px; */

}

.input-group img {
   height: 20px;
   width: 20px;
}

#table-head_rw {
   background-color: #F6F6F6 !important;
}

.userinfo {
   display: flex;
   align-items: center;
   text-transform: capitalize;
}

.userinfo span {
   margin-left: 15px;
}

#table-head_rw .table-hd {
   border: 0;
   font-weight: 600;
   padding: 15px;
   vertical-align: middle;
   position: relative;
   text-transform: capitalize;
}

.template-info__item {
   display: flex;
   align-items: center;
   margin-bottom: 12px;
}

.template-info__item :last-child {
   margin-bottom: 0px;
}

.template-info__item span {
   color: #BEBEBE;
   margin-right: 10px;
   min-width: 110px;
}

.template-info__item p {
   margin: 0;
   font-weight: 600;
}

.template-info__item--border {
   background-color: #ffffff;
   border: 1px solid #00AAF6;
   border-radius: 5px;
   padding: 9px 14px;
   width: 275px;
}

.o-icon {
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.o-icon img {
   width: 20px;
   margin-left: 15px;
   cursor: pointer;
}