.title-tab h4 {
    font-size: 22px;
}
.title-tab p {
    font-size: 18px;
    line-height:1.4;
}

@media (max-width:767px) {
    .left-wrap {
        display: block;
        padding: 50px 25px;
        height: auto;
    }
    .left-content {
        order: 2;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .left-wrap {
        display: block;
        padding: 50px;
        height: auto;
    }
    .left-content {
        order: 2;
    }
}
@media (min-width: 1001px) and (max-width: 1170px) {
    .left-wrap {
        display: block;
        padding: 30px 25px;
        height: auto;
    }
    .title-tab h4 {
        font-size: 18px;
    }
    .title-tab p {
        font-size: 16px;
        line-height:1.4;
    }
}
@media (min-width:1920px) {
    .title-tab h4 {
        font-size: 25px;
    }
    .title-tab p {
        font-size: 20px;
        line-height:1.4;
    }
}
